@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

#header,
.aboutSec,
.serviceSec,
.contactSec {
  position: relative;
  margin-bottom: 90px;
}
.contactSec{
  margin-top: 50px;
}
/*+++++++++++++++++++++++++++++++++++ NAVBAR CSS ++++++++++++++++++++++++++++++++++++*/
.brandImg {
  padding: 0;
  margin: 0;
  max-height: 40px;
}
#nav .flex-grow {
  flex-grow: 1 !important;
}
.navbar a {
  font-size: 1.1rem;
  text-transform: capitalize;
}

.menu-active {
  font-weight: 600 !important;
  border-bottom: 2px solid red !important;
  color: darkblue !important;
}

.navbar a:hover {
  color: darkblue !important;
}

@media (max-width: 991px) {
  .brandImg {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .brandImg img {
    padding: 0;
    margin: 0;
    max-height: 30px;
  }

  .mx-auto {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .navbar a {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 500;
  }

  .menu-active {
    font-weight: 600 !important;
    border-bottom: none !important;
    color: darkblue !important;
  }
}

/*+++++++++++++++++++++++++++++++++++++++ HOME PAGE CSS +++++++++++++++++++++++++++++++++++++++*/
.pt-custom {
  padding-top: 3rem;
}

.custom-Bac {
  background-color: darkblue;
  border-radius: 10px 100px 10px 0px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding-bottom: 50px;
}

#header {
  width: 100%;
  height: auto;
  background: white;
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 45px;
  font-weight: 700;
  color: white;
}

#header .brand-name {
  color: darkblue;
}

#header h2 {
  color: white;
  font-size: 20px;
  font-weight: 600;
}

#header .btn-get-started {
  font-weight: 700px;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  background: red;
  color: white;
  text-decoration: none;
}

.header-img {
  text-align: right;
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.seperator {
  margin-top: 10px;
  width: 40%;
}
.Wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}
.Wave .shape-fill {
  fill: white;
}
.Wave svg {
  position: relative;
  display: block;
  width: calc(133% + 1.3px);
  height: 130px;
}
@media (max-width: 991px) {
  #header {
    margin-top: 10px;
    height: auto;
    text-align: center;
    margin-bottom: 150px;
  }

  #header .header-img {
    text-align: center;
    max-width: 50%;
  }

  #header .header-img img {
    width: 70%;
  }

  #header h1 {
    font-size: 30px;
  }

  #header h2 {
    font-size: 15px;
  }

  #header .mt-3 {
    margin-top: 0 !important;
  }

  .seperator {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 800px) {
  #header .header-img {
    margin: auto;
  }
  .Wave svg {
    width: calc(100% + 1.3px);
    height: 80px;
  }
  .pt-custom {
    padding-top: 1rem;
  }
}

@media (max-width: 600px) {
  #header .header-img {
    max-width: 60%;
  }
}

@media (max-width: 500px) {
  #header .header-img {
    max-width: 80%;
  }
}

@media (max-width: 400px) {
  #header .header-img {
    max-width: 100%;
  }

  #header .header-img img {
    width: 80%;
  }
}

.fixed-cont {
  margin-top: 8rem;
  margin-bottom: 3rem;
}

/*++++++++++++++++++++++++++++ About Section css ++++++++++++++++++++++++++++++++*/
.heading {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 8px auto;
}

.heading h1 {
  font-size: 50px;
  font-weight: 900;
  color: red;
  margin-bottom: 25px;
  position: relative;
}

.aboutContent .mainPara {
  font-size: 25px;
  font-weight: 800;
  color: black;
}

/* .heading h1::before,
.heading h1::after {
  content: " ";
  display: block;
  border-bottom: 1px solid #c50000;
  border-top: 1px solid #c50000;
  height: 5px;
  background-color: #f8f8f8;
} */

.heading p {
  width: 80%;
  font-size: 1rem;
  color: #666;
  text-align: center;
  line-height: 1.5rem;
  white-space: 1px;
  margin-bottom: 40px;
}

.AboutContainer {
  width: 90%;
  margin: 0 auto;
  padding: 0 20px;
}

.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.aboutImage {
  flex: 1;
  margin-right: 40px;
  overflow: hidden;
  border-radius: 100px 10px 100px 10px;
  height: 500px;
}

.aboutImage img {
  width: 100%;
  height: 500px;
  display: block;
  transition: 0.5s ease;
}

.aboutImage:hover img {
  transform: scale(1.2);
}

.aboutContent {
  flex: 1;
}

.aboutContent h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #333;
  text-transform: uppercase;
}

.aboutContent h2::after {
  content: "";
  position: absolute;
  width: 10%;
  height: 2px;
  display: block;
  margin: 0 auto;
  background-color: red;
}

.aboutContent p {
  font-size: 18px;
  line-height: 1.5;
  color: #666;
}

.aboutContent .read-more {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #070e87;
  color: white;
  background-color: #070e87;
  font-size: 19px;
  text-decoration: none;
  border-radius: 25px;
  margin-top: 15px;
  transition: 0.3s ease;
}

@media (max-width: 991px) {
  .heading {
    padding: 0 20px;
  }

  .aboutContent .mainPara {
    font-size: 22px;
  }

  .heading h1 {
    font-size: 36px;
  }

  .heading p {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .AboutContainer {
    padding: 0;
  }

  .about {
    padding: 20px;
    flex-direction: column;
  }

  .aboutImage {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .aboutImage img {
    width: 100%;
    height: auto;
  }

  .aboutContent p {
    padding: 0;
    font-size: 16px;
  }

  .aboutContent h2::after {
    width: 35%;
  }

  .aboutContent .read-more {
    font-size: 16px;
  }
}

/*+++++++++++++++++++++++++++++++++++++ Service Section css ++++++++++++++++++++++++++++++++++++*/
.service {
  list-style: none;
  text-decoration: none;
  text-align: center;
  margin: auto;
}

#service-section {
  min-height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contactHeading {
  width: fit-content !important;
}

#service-section h2 {
  font-size: 50px;
  font-weight: 900;
  color: red;
  margin-bottom: 25px;
  position: relative;
}

/* #service-section h2::after,
#service-section h2::before {
  content: " ";
  display: block;
  border-bottom: 1px solid #c50000;
  border-top: 1px solid #c50000;
  height: 5px;
  background-color: #f8f8f8;
} */

#service-section #paragraph {
  width: 80%;
  text-align: center;
  line-height: 2rem;
  white-space: 2px;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  z-index: 9;
}

.cards .card {
  height: 350px;
  width: 380px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 8%;
  background: darkblue;
  position: relative;
  border-radius: 10px;
  margin: 0 10px;
}

.cards .card::before {
  position: absolute;
  content: " ";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background: red;
  transform: rotate(0deg);
  z-index: -1;
}

.cards .card:hover.card::before {
  transform: rotate(10deg);
}

.icons {
  color: rgb(75, 164, 253);
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cards .card h3 {
  font-size: 1.8rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

.cards .card .content p {
  color: white;
  font-size: 1.05rem;
  margin-bottom: 500;
}

@media (max-width: 1100px) {
  .serviceSec {
    margin-top: 30px;
  }

  #service h2 {
    margin-top: 30px;
  }

  .cards {
    grid-template-columns: repeat(1, 1fr);
  }

  #service-section #paragraph {
    width: 80%;
  }
}

@media (max-width: 900px) {
  #service-section .cards {
    padding: 0 15px;
  }

  #service-section h2 {
    font-size: 36px;
  }

  #service-section #paragraph {
    padding: 0 10px;
    width: 100%;
    letter-spacing: 1px;
    line-height: 1.5rem;
    font-size: 18px;
  }

  .cards .card {
    height: 300px;
    width: 100%;
    margin: 0;
  }

  .cards .card .content p {
    font-size: 0.9rem;
  }
}

/*+++++++++++++++++++++++++++++++++++++ About Page css ++++++++++++++++++++++++++++++++++++*/
.journey {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.journey-step {
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.welcome-section .AboutIcon {
  font-size: 80px;
  margin-bottom: 20px;
  padding: 20px;
  background: linear-gradient(
    to top,
    #070e87,
    #700077,
    #9f005e,
    #bb0042,
    #c71f25
  );
  border-radius: 5px 20px;
  color: white;
}

.welcome-section {
  text-align: center;
  padding: 50px 0;
  margin: 10px;
  border-radius: 20px 50px 0 50px;
  border-bottom: 4px solid darkblue;
  border-left: 4px solid darkblue;
  box-shadow: 10px -20px 100px 0 skyblue inset;
}

.welcome-text {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  color: darkblue;
}

.welcome-para {
  font-size: 20px;
  width: 50%;
  margin: auto;
  font-weight: 700;
}

.our-story-card {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
  border: 2px solid #666;
}

.our-story h2 {
  color: darkblue;
  font-weight: 700;
  font-size: 30px;
}

.our-story {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 700;
}

.why-choose-us {
  text-align: center;
  padding: 50px 20px 100px 20px;
}

.why-choose-us h2 {
  font-size: 50px;
  font-weight: 900;
  color: red;
  margin-bottom: 25px;
  position: relative;
}

.why-choose-us #para {
  width: 100%;
  text-align: center;
  line-height: 2rem;
  white-space: 2px;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.why-choose-us p {
  font-size: 18px;
  margin-bottom: 0;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}

.why-choose {
  flex: 1;
  padding: 20px;
  margin: 25px;
  text-align: center;
  border: 5px solid red;
  border-radius: 80px 80px 80px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: darkblue;
  color: white;
}

.why-choose h3 {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

.why-choose p {
  color: white;
  font-size: 1.05rem;
  margin-bottom: 500;
}

/* .meet-the-team {
  text-align: center;
  padding-bottom: 100px;
}

.team-card {
  display: inline-block;
  width: 300px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.team-image {
  max-width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.team-details {
  padding: 20px;
  text-align: left;
}

.team-name {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}

.team-title {
  font-size: 16px;
  color: #555;
} */

.join-us-card {
  width: 80%;
  margin: auto;
  border-top: 5px groove darkblue;
  border-right: 5px groove red;
  border-left: 5px groove darkblue;
  border-bottom: 5px groove red;
  border-radius: 15px 100px 15px 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: white;
}

.join-us-image {
  flex: 1;
  text-align: right;
}

.join-us-image img {
  width: 50%;
  height: 50%;
}

.join-us-text {
  flex: 1;
  text-align: left;
  padding-right: 40px;
}

.join-us-text h2 {
  font-size: 35px;
  font-weight: 700;
  color: red;
  margin-bottom: 20px;
}

.join-us-text p {
  font-size: 20px;
  font-weight: 700;
  color: black;
  line-height: 1.5rem;
  margin-bottom: 20px;
}

.join-button {
  display: inline-block;
  padding: 5px 10px;
  background-color: darkblue;
  color: white;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.mainIcons {
  font-size: 50px;
  padding-bottom: 10px;
  color: red;
}

.why-choose .aboutIcons {
  font-size: 50px;
  padding-bottom: 10px;
  color: rgb(75, 164, 253);
}

@media (max-width: 991px) {
  .our-story-card {
    max-width: 400px;
  }

  .our-story {
    font-size: 16px;
  }
  .join-us-card {
    flex-direction: column-reverse;
  }
  .join-us-text {
    text-align: center;
    padding-right: 0;
  }
  .join-us-image {
    text-align: center;
  }
  .join-us-image img {
    width: 60%;
    height: 50%;
  }

  .join-us-text p {
    font-size: 14px;
  }

  .join-us-text h2 {
    font-size: 20px;
    font-weight: 600;
  }

  .why-choose-us h2 {
    font-size: 36px;
  }
  .why-choose-us #para {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .welcome-para {
    font-size: 16px;
    text-align: left;
  }

  .our-story-card {
    max-width: 300px;
  }
}

/*+++++++++++++++++++++++++++++++++++++ Service Page css ++++++++++++++++++++++++++++++++++++*/
.introduction-section {
  display: flex;
  align-items: center;
  padding: 150px 20px;
}

.introduction-content {
  flex: 1;
  padding: 40px;
  text-align: left;
  position: relative;
  z-index: 1;
  background-color: darkblue;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border: 2px solid red;
}

.introduction-content:before,
.introduction-content:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 2px solid red;
  border-radius: 12px;
  z-index: -1;
}

.introduction-content h2 {
  font-size: 35px;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
}

.introduction-content p {
  font-size: 18px;
  font-weight: 500;
  color: whitesmoke;
  margin-bottom: 20px;
}

.introduction-image {
  flex: 1;
  overflow: hidden;
  text-align: right;
}

.introduction-image img {
  max-width: 80%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: 0.5s ease;
}

.introduction-image:hover img {
  transform: scale(1.2);
}

.employer-services,
.candidate-services {
  padding: 50px 20px;
  text-align: center;
  /* background-color: rgb(200, 200, 239); */
}

.employer-service-container,
.candidate-service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.employer-services h2,
.candidate-services h2 {
  font-size: 40px;
  margin-bottom: 10px;
  color: red;
  font-weight: 700;
}

.employer-services #para,
.candidate-services #para {
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.employer-service,
.candidate-service {
  width: calc(30% - 20px);
  margin-bottom: 30px;
  border-radius: 0px 80px 0px 80px;
  border: 3px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.service-content {
  padding: 30px;
  text-align: left;
  text-align: center;
}

.employer-service h3,
.candidate-service h3 {
  font-size: 28px;
  font-weight: 800;
  color: darkblue;
  margin-bottom: 10px;
}

.employer-service p,
.candidate-service p {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
}

.candidate-services {
  margin-top: 20px !important;
  padding: 0 20px !important;
}

.industry-expertise {
  padding: 100px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.industry-expertise h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
  color: red;
}

.industry-expertise #main {
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.expertise-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: calc(100% - 30px);
  padding: 30px;
  margin: 0 auto 30px;
  background-color: white;
  border-radius: 10px 80px 10px 80px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.expertise-card h3 {
  font-size: 35px;
  font-weight: 700;
  color: darkblue;
  margin-bottom: 10px;
  padding: 0 20px;
}

.expertise-card p {
  font-size: 22px;
  font-weight: 700;
  color: black;
  margin-bottom: 20px;
  padding: 0 20px;
}

.expertise-card img {
  width: 100%;
  border-radius: 100px 100px 100px 100px;
}

.technology-tools {
  padding: 0 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.technology-tools h2 {
  font-size: 40px;
  font-weight: 700;
  color: red;
  margin-bottom: 20px;
}

.tool {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  text-align: left;
}

.tool-icon {
  width: 100px;
  height: 100px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  color: white;
}

.brains {
  font-size: 42px;
}

.tool-content {
  flex: 1;
}

.tool h3 {
  font-size: 25px;
  font-weight: 700;
  color: darkblue;
  margin-bottom: 10px;
}

.tool p {
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin-bottom: 20px;
}

.get-started {
  padding: 30px 0;
  text-align: center;
  background-color: bisque;
}

.get-started h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
  color: red;
}

.get-started p {
  font-size: 25px;
  font-weight: 700;
  color: black;
  margin-bottom: 20px;
}

.cta-button {
  text-decoration: none;
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: darkblue;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-right: 10px;
}

.cta-button:hover {
  background-color: #070e87;
}

.serviceIcons {
  font-size: 50px;
  color: #007bff;
}
@media (max-width: 991px) {
  .introduction-section {
    flex-direction: column-reverse;
    padding: 80px 20px;
  }
  .introduction-content h2 {
    font-size: 28px;
  }
  .introduction-content:before,
  .introduction-content:after {
    content: none;
  }

  .introduction-content {
    text-align: center;
    margin-bottom: 40px;
  }

  .introduction-image {
    text-align: center;
    margin-bottom: 20px;
  }
  .employer-service-container{
    margin-top: 20px;
  }
  .employer-services h2,
  .candidate-services h2,
  .industry-expertise h2 {
    font-size: 36px;
  }
  .employer-services #para,
  .candidate-services #para,
  .industry-expertise #main {
    font-size: 16px;
  }
  .expertise-card {
    grid: inherit;
    width: 100%;
    margin-top: 20px;
  }
  .expertise-card h3{
    font-size: 26px;
    margin-top: 10px;
  }
  .expertise-card p{
    font-size: 18px;
  }
  .tool{
    margin-top: 20px;
  }
  .tool h3{
    font-size: 24px;
  }
  .tool p{
    font-size: 18px;
  }
  .get-started {
    padding: 20px 20px;
  }
  .get-started h2{
    font-size: 36px;
  }
  .get-started p{
    font-size: 18px;
  }
}

@media (max-width: 800px) {
  .employer-service-container,
  .candidate-service-container {
    flex-direction: column;
    align-items: center;
  }

  .employer-service,
  .candidate-service {
    width: 100%;
  }
}

/*+++++++++++++++++++++++++++++++++++++ Contact Page css ++++++++++++++++++++++++++++++++++++*/
/* .contactSec{
  background:#eee;
} */
.contact-area {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 768px) {
  .contact {
    margin-bottom: 60px;
  }
}

.contact input {
  background: #fff;
  border: 1px solid gray;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #232434;
  font-size: 16px;
  height: 60px;
  padding: 10px;
  width: 100%;
  font-family: "poppins", sans-serif;
  padding-left: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.contact textarea {
  background: #fff;
  border: 1px solid gray;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #232434;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  font-family: "poppins", sans-serif;
  padding-left: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.contact input:focus {
  background: #fff;
  border: 1px solid #fff;
  color: #232434;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 none;
}

.contact textarea:focus {
  background: #fff;
  border: 1px solid #fff;
  color: #232434;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 none;
}

.form-control::placeholder {
  color: #232434;
  opacity: 1;
}

.btn-contact-bg {
  border-radius: 30px !important;
  color: #fff !important;
  outline: medium none !important;
  padding: 15px 27px !important;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s !important;
  background: darkblue !important;
  cursor: pointer !important;
  width: 100%;
}

.btn-contact-bg:hover,
.btn-contact-bg:focus {
  background: #232434;
  color: #fff;
}

/*START ADDRESS*/

.single_address {
  overflow: hidden;
  margin-bottom: 10px;
  padding-left: 40px;
}

@media only screen and (max-width: 768px) {
  .single_address {
    padding-left: 0px;
  }
}

.single_address i {
  background: #f6f6f6;
  color: darkblue;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  float: left;
  margin-right: 14px;
  font-size: 22px;
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single_address:hover i {
  background: darkblue;
  color: #fff;
}

.single_address h4 {
  font-size: 18px;
  margin-bottom: 0px;
  overflow: hidden;
  font-weight: 600;
}

.single_address p {
  overflow: hidden;
  margin-top: 5px;
}

.section-title h1 {
  font-size: 50px;
  font-weight: 900;
  color: red;
  margin-bottom: 25px;
  position: relative;
}

.section-title p {
  padding: 0 10px;
  width: 70%;
  margin: auto;
  letter-spacing: 1px;
  text-align: center;
  line-height: 2rem;
  white-space: 2px;
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.section-title {
  margin-bottom: 60px;
}

.text-center {
  text-align: center !important;
}

@media (max-width: 991px) {
  .section-title h1 {
    font-size: 36px;
  }

  .section-title p {
    padding: 0 10px;
    width: 100%;
    letter-spacing: 1px;
    line-height: 1.5rem;
    font-size: 18px;
  }
}

/*+++++++++++++++++++++++++++++++++++++ Footer css ++++++++++++++++++++++++++++++++++++*/
footer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 100px;
  background: #070e87;
}

.cust-cont {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.cust-cont .sec h2 {
  position: relative;
  color: white;
  font-weight: 500;
  margin-bottom: 15px;
}

.cust-cont .sec h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 80px;
  height: 2px;
  background: red;
}

.cust-cont .sec p {
  color: white;
}

.sci {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 50px);
  grid-gap: 10px;
  padding-left: 0;
}

.sci li,
.quickLinks li {
  list-style: none;
}

.sci li a {
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #3e56cb;
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  font-size: 20px;
}

.sci li a:hover {
  background: #6380fe;
}

.quickLinks ul {
  padding-left: 0;
}

.quickLinks {
  position: relative;
}

.quickLinks li a {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  display: inline-block;
}

.quickLinks li a:hover {
  color: red;
}

.info {
  position: relative;
  padding-left: 0;
}

.info li {
  display: grid;
  grid-template-columns: 30px 1fr;
  margin-bottom: 16px;
}

.info li span:nth-child(1) {
  color: white;
  font-size: 20px;
}

.info li span {
  color: white;
}

.info a {
  color: white;
  text-decoration: none;
}

.copyright {
  width: 100%;
  background: darkblue;
  padding: 8px 100px;
  text-align: center;
  color: white;
}

.copyright p,
.info p {
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  footer {
    padding: 40px;
  }

  .cust-cont {
    grid-template-columns: repeat(2, 1fr);
  }

  .copyright {
    padding: 8px 40px;
  }
}

@media (max-width: 991px) {
  .cust-cont {
    grid-template-columns: repeat(1, 1fr);
  }
}
